


































































import Vue from "vue";
import { mapState } from "vuex";
import { dispatch } from "@/store";
import { download } from "@/utils";

export default Vue.extend({
  computed: {
    ...mapState("app", {
      isCN: "isCN",
      isMobile: "isMobile",
      language: "language"
    }),
    ...mapState("assets", {
      oss: "oss"
    })
  },
  methods: {
    onDownload(platform: "windows" | "mac") {
      dispatch
        .diyGetAMMaster(platform)
        .then(res => {
          if (res.data && res.data[0]) {
            download(res.data[0].url);
          }
        })
        .catch(() => {
          this.$message.info(this.$t("error.download_failed") + "");
        });
    }
  }
});
